<template>
  <div class="AllInactiveClients">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol sm="6">
        <h1> Clients archivés du cabinet </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('/add-new-client')"
          size="sm"
          shape="pill"
          color="outline-success">
            <CIcon name="cil-plus"/> Ajouter un nouveau client
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>

        <CDataTable
          class="cursor-table"
          hover
          :items="inactiveClientsRender"
          :fields="inactiveClientsRenderFields"
          @row-clicked="goToClientPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #is_firm="{item}">
          <td>
            {{companyTypeRender(item.is_firm)}}
          </td>
        </template>

        <template #email="{item}">
          <td v-if="item.email">
            {{item.email}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>

        <template #phone_number="{item}">
          <td v-if="item.phone_number">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>

        </CDataTable>
        <CRow>
          <CCol class="text-center">
            <CButton
              v-if="getAllInactiveClientsUrl"
              @click="getAllInactiveClients()"
              class="px-4"
              color="outline-dark">
              Voir plus
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { APIManagerConnected } from '@/api/APIManagerConnected'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'AllInactiveClients',
  components: {
    Loading,
  },
  mixins: [
    renderMixins
  ],
  data: function () {
    return {
      // ------------- MISSION -----------
      inactiveClients: [],
      inactiveClientsRender: [],
      inactiveClientsRenderFields: [
        { key: "is_firm", label: "Type", tdClass: 'ui-helper-center', sortable: true},
        { key: "name", label: "Nom du client", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
        { key: "collaborateur", label: "Collaborateur", tdClass: 'ui-helper-center'},
      ],
      isAllInactiveClientsLoading: false,


      basegetAllInactiveClientsUrl: apiBaseUrl + '/all-inactive-clients',
      getAllInactiveClientsUrl: apiBaseUrl + '/all-inactive-clients',
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllInactiveClientsLoading) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.getAllInactiveClients()
  },
  watch: {
    inactiveClients: function (newClients) {
      if (newClients.length == 0) {
        this.inactiveClientsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newClients.length; i++) {
          final_array.push(
            {
              'id': newClients[i].id,
              'is_firm': newClients[i].is_firm,
              'siren': newClients[i].siren,
              'name': newClients[i].name,
              'email': newClients[i].email,
              'phone_country_code': newClients[i].phone_country_code,
              'phone_number': newClients[i].phone_number,
              'address': newClients[i].address,
              'collaborateur': newClients[i].collaborateur.full_name,
            }
          )
        }
        this.inactiveClientsRender = final_array
      }
    },
  },
  methods: {
    getAllInactiveClients () {
      this.isAllInactiveClientsLoading = true

      apiManagerConnected.getAllInactiveClients(this.token, this.getAllInactiveClientsUrl)
      .then((result) => {
        this.inactiveClients = this.inactiveClients.concat(result.data.results)
        this.getAllInactiveClientsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllInactiveClientsLoading = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToClientPage(item) {
      this.$router.push({ name: 'Visualiser votre client', params: { id: item.id } })
    }

  }

}
</script>
